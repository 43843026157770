import clsx from 'clsx'
import Link from 'next/link'
import map from 'lodash/map'
import filter from 'lodash/filter'
import { notify } from '@qureos/lib'
import { useLogout } from 'src/hooks'
import { ShouldRender } from '../misc'
import { useRouter } from 'next/router'
import { FunctionComponent, useContext } from 'react'
import { user as userAtom } from 'src/atoms'
import { Avatar, Dropdown, Menu } from 'antd'
import RenderMenuItem from './RenderMenuList'
import { UserRole } from 'src/types/shared/graphql'
import { subscription } from 'src/atoms/subscription'
import { segmentTrackCallback } from '@/utils/segment'
import { useRecoilValue } from 'recoil'
import { useJobStats } from 'src/hooks/data/useJobStats'
import { DEFAULT_USER_SIGNUP_CODE } from 'statics/global'
import { MENU } from '@/utils/static-helpers/sidebar-helper'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { getUserInitials } from '@/utils/static-helpers/initials'
import { SubscriptionStatus } from 'src/types/shared/subscription'
import { ApprenticeProfile, SegmentEventType } from '@qureos/types'
import {
  PageInterface,
  SidebarProps,
  SidebarSectionsEnum
} from 'statics/interface'
import { useTranslation } from 'react-i18next'
import { WorkspaceContext } from 'src/context/workspace/workspace.context'

const ApprenticeSideMenuV2: FunctionComponent<SidebarProps> = ({
  showLabel,
  role,
  signupCode,
  user,
  isMobile = false,
  isDrawerOpen = false
}) => {
  const { t: Label } = useTranslation('Sidebar')
  const subscriptionStatus = useRecoilValue(subscription)
  const profile = useRecoilValue<ApprenticeProfile>(userAtom)
  const isVfsServiceEnable = profile?.defaultCompany?.isVfs || false
  const labelClass: string[] = ['text-sm', 'font-base', 'normal-case']

  const {
    jobStats: { uniqueJobCountWithUpdates }
  } = useJobStats()
  const router = useRouter()
  const logout = useLogout()
  const { activeWorkspace } = useContext(WorkspaceContext)

  if (!role) {
    return null
  }

  const filterPages = (page: PageInterface) =>
    !page.restriction.includes(signupCode || DEFAULT_USER_SIGNUP_CODE)

  const pages = filter(MENU(isVfsServiceEnable, activeWorkspace?._id), item =>
    item.permissions.includes(role)
  )

  const filterBySubscription = (page: PageInterface) => {
    const { Subscribed, Never_Subscribed, Expired, Trial, Cancelled } =
      SubscriptionStatus
    let show = true
    if (
      [Never_Subscribed].includes(subscriptionStatus) &&
      page.restriction.includes('subscribed')
    ) {
      show = false
    }
    if (
      [Subscribed, Expired, Trial, Cancelled].includes(subscriptionStatus) &&
      page.restriction.includes('not-subscribed')
    ) {
      show = false
    }

    if (page.restriction.includes('for-you')) {
      show = false
    }

    if (page.restriction.includes('vfs-service') && !isVfsServiceEnable) {
      show = false
    }

    return show
  }

  const handleClick = ({ page }): string => {
    if (page.pathname === 'community') {
      segmentTrackCallback(SegmentEventType.COMMUNITY_BUTTON, {
        id: user._id,
        email: user.email,
        firstName: user.firstName,
        date: new Date()
      })
    }
    return page.link
  }

  const menuItems = [
    { label: 'Privacy Policy', path: '/privacy-policy' },
    { label: 'Terms and Conditions', path: '/terms-conditions' }
  ]

  const onLogout = () => {
    try {
      logout()
      notify({ message: Label('LOGOUT_MESSAGE'), type: 'success' })
      router.replace('/corporate/login')
    } catch (error) {
      notify({ message: error.message, type: 'error' })
    }
  }

  const menu = (
    <Menu className="w-[224px] text-gray-900 px-1 rounded-md ring-1 ring-black/5 shadow-lg  mt-2">
      {!isVfsServiceEnable && user.role === UserRole.Professional && (
        <Menu.Item className="border-b-2 border-gray-100 py-0 transition-colors hover:bg-transparent">
          <Link href="/settings" passHref>
            <a className="my-3 font-medium w-[180px] truncate" href="/settings">
              {Label('SETTING')}
            </a>
          </Link>
        </Menu.Item>
      )}
      <div className="my-3 flex flex-col gap-3">
        {menuItems.map((item, i) => (
          <Menu.Item
            key={`${item}-${i}`}
            className="py-0 hover:text-qureosPrimary transition-colors hover:bg-transparent"
          >
            <Link href={item.path} passHref>
              <a href={item.path}>{item.label}</a>
            </Link>
          </Menu.Item>
        ))}
      </div>
      <Menu.Item
        onClick={onLogout}
        className="border-t-2 border-gray-100 py-0 transition-colors hover:text-qureosPrimary hover:bg-transparent"
      >
        <div className="my-3">
          <p>{Label('SIGN_OUT')}</p>
        </div>
      </Menu.Item>
    </Menu>
  )

  const filteredList = filter(filter(pages, filterPages), filterBySubscription)

  return (
    <>
      <div className="flex-1 flex flex-col overflow-auto font-inter max-h-dvh mb-8 custom-scrollbar">
        {map(
          filter(filteredList, {
            section: SidebarSectionsEnum.PRIMARY_SECTION
          }),
          page =>
            RenderMenuItem({
              page,
              user,
              handleClick,
              isMobile,
              isDrawerOpen,
              showLabel,
              labelClass,
              uniqueJobCountWithUpdates
            })
        )}
        <div className="mx-2 my-3 border-t border-gray-200" />
        {map(
          filter(filteredList, {
            section: SidebarSectionsEnum.SECONDARY_SECTION
          }),
          page =>
            RenderMenuItem({
              page,
              user,
              handleClick,
              isMobile,
              isDrawerOpen,
              showLabel,
              labelClass,
              uniqueJobCountWithUpdates
            })
        )}
      </div>
      <div className="flex flex-col font-inter">
        <div className="flex flex-col overflow-auto bg-gray-50 mt-auto">
          {map(
            filter(filteredList, {
              section: SidebarSectionsEnum.UTILITY_SECTION
            }),
            page =>
              RenderMenuItem({
                page,
                user,
                handleClick,
                isMobile,
                isDrawerOpen,
                showLabel,
                labelClass,
                uniqueJobCountWithUpdates
              })
          )}
        </div>
        <div className="m-0 border-t border-gray-200" />
        <div
          className={clsx(
            'flex justify-between items-center font-inter hover:cursor-pointer',
            isDrawerOpen ? 'p-4' : 'py-4'
          )}
        >
          <ShouldRender check={isDrawerOpen}>
            <div className="flex gap-4">
              <Link href="/profile">
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    segmentTrackCallback(
                      SegmentEventType.PROFILE_PIC_TO_PROFILE,
                      {
                        dateTime: new Date(),
                        fromUrl: router.asPath,
                        redirectUrl: '/profile',
                        email: user?.email,
                        userId: user._id,
                        firstName: user?.firstName,
                        lastName: user?.lastName
                      }
                    )
                  }}
                >
                  <Avatar size={50} src={user?.photo} alt="profile-picture">
                    {getUserInitials(`${user?.firstName} ${user?.lastName}`)}
                  </Avatar>
                </a>
              </Link>

              <div className="grid">
                <span className="font-medium text-sm text-gray-900 w-full max-w-[144px] overflow-hidden whitespace-nowrap text-ellipsis">{`${user?.firstName} ${user?.lastName}`}</span>
                <span className="text-gray-500">
                  {user?.role === UserRole.Mentor && 'Mentor Profile'}
                  {user?.role === UserRole.Student && 'Candidate Profile'}
                  {user?.role === UserRole.Professional &&
                    !isVfsServiceEnable &&
                    'Corporate Profile'}
                  {user?.role === UserRole.Professional &&
                    isVfsServiceEnable &&
                    'Business Profile'}
                </span>
              </div>
            </div>
          </ShouldRender>
          <Dropdown placement="topCenter" overlay={menu} trigger={['click']}>
            {isDrawerOpen ? (
              <EllipsisVerticalIcon
                width={26}
                height={26}
                className="text-gray-400 cursor-pointer"
              />
            ) : (
              <Avatar
                size={50}
                src={user?.photo}
                alt="profile-picture"
                className="mx-auto"
              >
                {getUserInitials(`${user?.firstName} ${user?.lastName}`)}
              </Avatar>
            )}
          </Dropdown>
        </div>
      </div>
    </>
  )
}

export default ApprenticeSideMenuV2
