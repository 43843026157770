import clsx from 'clsx'
import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import kebabCase from 'lodash/kebabCase'
import { User } from 'src/types/shared/graphql'
import { PageInterface } from '@qureos/statics/interface'
import { isActive } from '@/utils/static-helpers/sidebar-helper'

type RenderMenuItemProps = {
  user: User
  isMobile: boolean
  showLabel: boolean
  labelClass: string[]
  page: PageInterface
  isDrawerOpen: boolean
  uniqueJobCountWithUpdates: number
  handleClick: (data: { page: PageInterface }) => void
}

const RenderMenuItem: React.FC<RenderMenuItemProps> = ({
  page,
  user,
  isMobile,
  showLabel,
  labelClass,
  handleClick,
  isDrawerOpen,
  uniqueJobCountWithUpdates
}) => {
  const router = useRouter()

  const textStyle = isDrawerOpen ? 'text-gray-800' : 'text-gray-500'

  const iconType = isActive(router?.pathname, page.pathname, user)
    ? 'active'
    : 'normal'

  return (
    <Link
      href={page.link ? page.link : page.url}
      key={kebabCase(`sidebar-route-${page.pathname}`)}
      passHref
    >
      <a
        className={clsx('group mx-2')}
        href={page?.link}
        onClick={() => handleClick({ page })}
        target={page?.link ? '_blank' : ''}
        rel="noreferrer noopener"
      >
        <div
          className={clsx(
            'flex gap-x-3 items-center rounded-lg px-auto p-2 hover:bg-gray-100',
            isActive(router?.pathname, page.pathname, user)
              ? 'bg-qureosPrimaryLight text-qureosPrimary'
              : textStyle
          )}
        >
          {isMobile ? page.icon[`${iconType}Mobile`] : page.icon[iconType]}
          {showLabel && isDrawerOpen && (
            <div className="flex justify-between items-center w-full gap-3">
              <div
                className={clsx(
                  labelClass,
                  'font-medium w-full',
                  isActive(router?.pathname, page.pathname, user)
                    ? 'text-qureosPrimary'
                    : textStyle
                )}
              >
                {page.label}
              </div>
              <div>
                {page.showNotificationBadge && uniqueJobCountWithUpdates > 0 ? (
                  <span className="bg-red-500 text-white font-medium px-2 py-1 rounded-2xl">
                    +{uniqueJobCountWithUpdates}
                  </span>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </a>
    </Link>
  )
}

export default RenderMenuItem
