import { Menu as AntdMenu, MenuProps as AntdMenuProps } from 'antd'
import clsx from 'clsx'

// Extend the Antd Menu props
export interface MenuProps extends AntdMenuProps {}

const Menu = (props: MenuProps) => (
  <AntdMenu {...props} className={clsx('menu-wrapper', props.className)} />
)

// Forward all static properties from AntdMenu to Menu
export default Object.assign(Menu, AntdMenu)
