import { isLoggedIn } from '@qureos/lib'
import { UserRole } from '@qureos/types'
import { useEffect } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { jobStats as jobStatsAtom, user as userAtom } from 'src/atoms'
import { getJobStats, getJobTabsCount } from 'src/services/jobs/jobs'

export const useJobStats = () => {
  const [jobStats, setJobStats] = useRecoilState(jobStatsAtom)
  const profile = useRecoilValue(userAtom)

  const updateJobStats = async (params?: { jobId: string | null }) => {
    const selectedJobId = jobStats.currentJobId
    let jobId = selectedJobId
    if (params) {
      jobId = params.jobId
    }
    let currentJobUpdates = {
      unseenPipelineApplicants: 0,
      unseenScreenedApplicants: 0,
      unseenShortlistedApplicants: 0,
      aiInterviewUpdates: 0
    }

    const { uniqueJobCountWithUpdates } = await getJobStats()
    if (jobId) {
      currentJobUpdates = await getJobTabsCount(jobId)
    }
    setJobStats({
      uniqueJobCountWithUpdates,
      currentJobUpdates,
      currentJobId: jobId,
      fetched: true
    })
  }

  useEffect(() => {
    if (
      !isLoggedIn() ||
      profile?.role !== UserRole.PROFESSIONAL ||
      jobStats.fetched
    ) {
      return
    }
    getJobStats().then(stats =>
      setJobStats(prev => ({ ...prev, ...stats, fetched: true }))
    )
  }, [profile])

  return { jobStats, updateJobStats }
}
