import { useState, useEffect } from 'react'

export const PLATFORM_VERSION_KEY = 'platform_version'

export const setPlatformVersion = (version: 'v1' | 'v2'): void => {
  if (typeof window === 'undefined') return

  localStorage.setItem(PLATFORM_VERSION_KEY, version)
  window.dispatchEvent(
    new StorageEvent('storage', {
      key: PLATFORM_VERSION_KEY,
      newValue: version
    })
  )
}

export const getPlatformVersion = (): 'v1' | 'v2' => {
  if (typeof window === 'undefined') return 'v1'

  const version = localStorage.getItem(PLATFORM_VERSION_KEY)
  return version === 'v2' ? 'v2' : 'v1'
}

export const usePlatformVersion = () => {
  const [version, setVersion] = useState<'v1' | 'v2'>('v1')

  useEffect(() => {
    setVersion(getPlatformVersion())

    const handleStorageEvent = (e: StorageEvent) => {
      if (e.key === PLATFORM_VERSION_KEY) {
        setVersion(e.newValue === 'v2' ? 'v2' : 'v1')
      }
    }

    window.addEventListener('storage', handleStorageEvent)
    return () => window.removeEventListener('storage', handleStorageEvent)
  }, [])

  return version
}
