import { atom } from 'recoil'

export const sidebarOpen = atom({
  default: false,
  key: 'sidebarOpen'
})

export const apprenticesCohortSidebarOpen = atom({
  default: false,
  key: 'apprenticesCohortSidebarOpen'
})

export const apprenticeSidebarOpen = atom({
  default: false,
  key: 'apprenticeSidebarOpen'
})
