import Pusher from 'pusher-js'

if (!process.env.PUSHER_APP_KEY) {
  throw new TypeError('Missing PUSHER_APP_KEY.')
}

if (!process.env.PUSHER_APP_CLUSTER) {
  throw new TypeError('Missing PUSHER_APP_CLUSTER.')
}

const pusher = new Pusher(process.env.PUSHER_APP_KEY, {
  cluster: process.env.PUSHER_APP_CLUSTER
})

export default pusher
