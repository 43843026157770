export const QureosLogoBlue = props => {
  return (
    <svg
      width="inherit"
      height="inherit"
      viewBox="0 0 106 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M27.813 19.2977C23.628 19.2977 21.085 16.1507 21.085 12.0715V1.02157C21.085 0.855316 21.2213 0.71875 21.3873 0.71875H24.2978C24.4638 0.71875 24.6001 0.855316 24.6001 1.02157V11.5431C24.6001 14.0132 25.7205 16.1863 28.6784 16.1863C31.5178 16.1863 32.9405 14.316 32.9405 11.5787V1.02157C32.9405 0.855316 33.0768 0.71875 33.2428 0.71875H36.1533C36.3193 0.71875 36.4556 0.855316 36.4556 1.02157V15.4026C36.4556 16.6079 36.5386 17.7123 36.6038 18.407C36.6216 18.5852 36.4853 18.7395 36.3015 18.7395H33.5332C33.3791 18.7395 33.2546 18.6267 33.2368 18.4723C33.2072 18.2111 33.1776 17.8608 33.1539 17.4986C33.1361 17.2254 32.7982 17.1126 32.6204 17.3264C31.5 18.6683 29.6031 19.2977 27.813 19.2977Z"
        fill="#3461ff"
      />
      <path
        d="M49.4197 3.93656C49.4197 4.11469 49.2656 4.25719 49.0878 4.23344C48.6787 4.18594 48.2697 4.16219 47.8903 4.16219C44.9383 4.16219 42.9525 5.73567 42.9525 9.70796V18.4304C42.9525 18.5966 42.8162 18.7332 42.6502 18.7332H39.7397C39.5737 18.7332 39.4374 18.5966 39.4374 18.4304V1.02117C39.4374 0.854914 39.5737 0.718348 39.7397 0.718348H42.5791C42.7451 0.718348 42.8814 0.854914 42.8814 1.02117V2.72527C42.8814 3.02216 43.2667 3.14091 43.4327 2.89153C44.7546 0.926166 46.6633 0.415527 48.2579 0.415527C48.5898 0.415527 48.9218 0.451153 49.1589 0.486779C49.3071 0.51053 49.4138 0.635221 49.4138 0.783662L49.4197 3.93656Z"
        fill="#3461ff"
      />
      <path
        d="M66.8591 13.5021C67.0192 13.5555 67.1021 13.7336 67.0429 13.894C65.9522 16.94 63.1246 19.2972 59.0641 19.2972C54.2389 19.2972 50.0125 15.7762 50.0125 9.67228C50.0125 4.01369 54.0907 0.160156 58.6136 0.160156C64.1501 0.160156 67.2503 3.97807 67.2503 9.60103C67.2503 9.90979 67.2326 10.2185 67.2089 10.456C67.1911 10.6104 67.0666 10.7292 66.9065 10.7292H53.9544C53.7766 10.7292 53.6402 10.8776 53.6521 11.0558C53.8714 14.1018 56.1358 16.1978 59.0582 16.1978C61.8442 16.1978 63.2965 14.7312 64.0079 12.837C64.0671 12.6827 64.2331 12.6055 64.3872 12.6589L66.8591 13.5021ZM63.2669 7.87317C63.4447 7.87317 63.5811 7.71879 63.5692 7.5466C63.3677 5.12404 61.696 3.26555 58.6492 3.26555C55.8394 3.26555 54.0789 5.33185 53.7825 7.52879C53.7588 7.70692 53.9011 7.86723 54.0848 7.86723H63.2669V7.87317Z"
        fill="#3461ff"
      />
      <path
        d="M91.2932 13.0451C91.4651 12.9857 91.6489 13.0926 91.6904 13.2707C92.0401 14.9808 93.4391 16.293 95.822 16.293C97.8019 16.293 98.8867 15.1708 98.8867 13.8942C98.8867 12.772 98.0627 11.911 96.5689 11.5726L93.5043 10.8957C90.7005 10.296 89.017 8.38404 89.017 5.83679C89.017 2.76702 91.8979 0.142578 95.413 0.142578C100.09 0.142578 101.714 3.03422 102.188 4.70863C102.23 4.85708 102.147 5.01739 102.005 5.07083L99.4854 6.01492C99.3135 6.08023 99.1357 5.97929 99.0882 5.80116C98.8274 4.78582 97.956 3.14109 95.419 3.14109C93.6228 3.14109 92.4254 4.30487 92.4254 5.53991C92.4254 6.6265 93.1012 7.41027 94.4824 7.71309L97.3988 8.34842C100.653 9.06094 102.372 11.0441 102.372 13.7042C102.372 16.2514 100.238 19.2856 95.7924 19.2856C91.0621 19.2856 88.9399 16.3583 88.5072 14.3039C88.4776 14.1554 88.5606 14.007 88.7028 13.9595L91.2932 13.0451Z"
        fill="#3461ff"
      />
      <path
        d="M78.2582 0.160359C72.7335 -0.0177703 68.2225 4.50672 68.3944 10.0406C68.5545 15.052 72.6268 19.1312 77.6298 19.2915C83.1545 19.4696 87.6655 14.9451 87.4936 9.41122C87.3395 4.39984 83.2612 0.320676 78.2582 0.160359ZM78.2641 15.8595C74.6245 16.0436 71.6369 13.051 71.8206 9.40529C71.9807 6.28802 74.5118 3.75264 77.6239 3.59232C81.2635 3.40826 84.2511 6.40083 84.0674 10.0466C83.9132 13.1638 81.3821 15.6992 78.2641 15.8595Z"
        fill="#3461ff"
      />
      <path
        d="M3.77008 16.5186C4.89042 16.2693 6.32494 16.0436 7.91358 16.0258C10.1958 16.0021 12.306 16.3999 14.197 17.2015C14.4519 17.3083 14.7008 17.4212 14.9439 17.5458C17.7655 15.6339 19.4727 12.2732 19.1111 8.6453C18.5835 3.3786 13.9421 -0.451184 8.67826 0.0475788C3.38478 0.546342 -0.480116 5.26084 0.0474538 10.5513C0.290492 12.9976 1.43455 15.1352 3.1121 16.673C3.31957 16.6196 3.53297 16.5661 3.77008 16.5186ZM9.28881 3.44392C12.4127 3.28954 15.1869 5.5696 15.6552 8.66311C16.2006 12.2791 13.5212 15.5627 9.86973 15.7408C6.74581 15.8952 3.97162 13.6151 3.50333 10.5216C2.95798 6.91151 5.63732 3.62205 9.28881 3.44392Z"
        fill="#3461ff"
      />
      <path
        d="M19.6623 22.0346C18.1626 20.2058 16.3428 18.8342 14.2681 17.9555C12.3771 17.1539 10.2668 16.7561 7.98466 16.7798C6.39603 16.7976 4.96151 17.0233 3.84117 17.2726C2.98164 17.4626 2.30588 17.6705 1.87908 17.8189C1.60048 17.9139 1.45821 18.2227 1.56491 18.4958L2.25846 20.2118C2.35923 20.4612 2.63784 20.5918 2.89273 20.5087C3.75225 20.2237 5.74398 19.6655 8.10322 19.6536C11.98 19.6299 15.1098 21.0312 17.4039 23.8159C17.5817 24.0297 17.8899 24.0653 18.1093 23.8991L19.5734 22.7768C19.8105 22.5928 19.8461 22.2603 19.6623 22.0346Z"
        fill="#3461ff"
      />
      <path
        d="M103.896 0.855279H103.493V0.647461H104.518V0.855279H104.115V1.91218H103.89V0.855279H103.896Z"
        fill="#3461ff"
      />
      <path
        d="M104.755 0.647461H104.992L105.378 1.24716L105.763 0.647461H106V1.91218H105.781V1.00372L105.383 1.60342H105.378L104.98 1.00966V1.91218H104.761V0.647461H104.755Z"
        fill="#3461ff"
      />
    </svg>
  )
}

export default QureosLogoBlue
