import { atom } from 'recoil'
import { JobsMeta } from '@qureos/types'

export const jobs = atom({
  default: undefined,
  key: 'jobs'
})

export const job = atom({
  default: undefined,
  key: 'job'
})

export const jobsMeta = atom({
  default: {
    companies: [],
    contractTypes: [],
    locations: [],
    themes: []
  } as JobsMeta,
  key: 'jobsMeta'
})

export const FYPFilter = atom({
  default: {
    companies: [],
    contractTypes: [],
    locations: [],
    themes: []
  },
  key: 'FYPFilter'
})

export const jobStats = atom({
  default: {
    uniqueJobCountWithUpdates: 0,
    fetched: false,
    currentJobId: null,
    currentJobUpdates: {
      unseenPipelineApplicants: 0,
      unseenScreenedApplicants: 0,
      unseenShortlistedApplicants: 0,
      aiInterviewUpdates: 0
    }
  },
  key: 'jobStats'
})
